import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import UserModal from './UserModal';
import LoadingOverlay from './Loader'; // Import the loader component

function JoinOTPModal({ isVisible, onClose }) {
  const [userModalVisible, setUserModalVisible] = useState(false);
  const [data, setData] = useState({ verified: false, allowedTickets: 0, gameID: null });
  const apiUrl = 'https://server.tb.devlx.net/users/verify-otp';
  const navigate = useNavigate();
  const [otpInput, setOtpInput] = useState('');
  const [showWarning, setShowWarning] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    if (otpInput === '') {
      setShowWarning('Please enter an OTP');
      return;
    }

    setLoading(true);

    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ otp: otpInput }),
    })
      .then((response) => {
        setLoading(false);

        if (!response.ok) {
          setShowWarning(response.message);
          return;
        }
        return response.json();
      })
      .then((responseData) => {
        if (responseData && responseData.verified) {
          setData(responseData);
          setUserModalVisible(true);
        } else {
          setShowWarning('Wrong OTP');
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const closeUserModal = () => {
    setUserModalVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-opacity-30 bg-black backdrop-blur-md z-50">
            {loading && <LoadingOverlay />}
      <div className="bg-white w-full h-auto overflow-hidden flex flex-col justify-between">
        <div className="bg-[#E2DD20] text-center text-[#2C382E] h-auto py-4 text-3xl font-semibold tracking-wider font-sans">
          Enter Game OTP
        </div>
        <div className="bg-[#2C382E] p-4 h-full">
        <div><small className='text-[#818052] p-1'>4-digit Numeric Code</small></div>
        <div>
          <input
            type='text'
            id='otp-input'
            className={`w-[50%] rounded-lg p-6 border-[#D3D196] h-10 border text-[#D3D196] text-center bg-[#2C382E] text-lg ${
              showWarning ? 'border-red-500' : '' // Add red border if showWarning is true
            }`}
            placeholder='0000'
            maxLength={4}
            value={otpInput}
            onChange={(e) => {
              setOtpInput(e.target.value);
              setShowWarning(false); // Clear warning when input changes
            }}
          />
        </div>
        <div>  
          {showWarning && (
            <span className='text-red-500 text-xs tracking-wider'>{showWarning}</span>
          )}
        </div>
        
          <div className=' py-2'>
            <p className=' text-xl text-[#D3D196] tracking-wider'>Ask the game creator for OTP to join the game</p>
          </div>
          <div className="pb-2">
              <button
                className="bg-[#E2DD20] text-[#2C382E] rounded-lg text-xl font-medium mt-3 px-6 py-1 w-[75%]"
                onClick={handleClick}
              >
                Verify OTP
              </button>
          </div>
        </div>
      </div>
      <UserModal
        isVisible={userModalVisible}
        onClose={closeUserModal}
        allowedTickets={data.allowedTickets}
        gameID={data.gameID}
      />
    </div>
  );
}

export default JoinOTPModal;
