import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function UserModal({ isVisible, onClose, allowedTickets, gameID }) {
  const [ticketValue, setTicketValue] = useState('1');
  const [validationMessage, setValidationMessage] = useState('');
  const navigate = useNavigate();

  const handleStartGame = () => {
    if (ticketValue <= allowedTickets) {
      setValidationMessage('');
      navigate(`/ticket?ticketValue=${ticketValue}&gameID=${gameID}`);
    } else {
      setValidationMessage('Enter a value below or equal to the maximum allowed.');
    }
  };

  const handleInputChange = (e) => {
    const enteredValue = e.target.value;
    if (enteredValue <= allowedTickets) {
      setTicketValue(enteredValue);
      setValidationMessage(''); // Clear the validation message when the input is valid
    } else {
      setValidationMessage('Enter a value below or equal to the maximum allowed.');
    }
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className='fixed inset-0 flex justify-center items-center bg-opacity-30 bg-black backdrop-blur-md z-50'>
      <div className='bg-white w-full h-auto overflow-hidden flex flex-col justify-between'>
        <div className='bg-[#E2DD20] text-center text-[#2C382E] h-[20%] py-4 text-3xl font-semibold tracking-wider font-sans'>
          Play With
        </div>
        <div className='bg-[#2C382E] p-4 h-full'>
          <div>
            <small className='text-[#818052] p-1'>
              Max. {allowedTickets} allowed
            </small>
          </div>
          <div>
            <input
              type='text'
              className='w-[50%] rounded-lg p-6 border-[#D3D196] h-10 border text-[#D3D196] text-center bg-[#2C382E] text-lg'
              value={ticketValue}
              onChange={handleInputChange}
            />
          </div>
          <div><span className='text-red-500 text-xs'>{validationMessage}</span></div>
          <div className='py-3.5'>
            <p className='text-xl text-[#D3D196] tracking-wider'>
              Select the number of tickets you want to play with
            </p>
          </div>
          <div className='pb-2'>
            <button
              className='bg-[#E2DD20] text-[#2C382E] rounded-lg text-xl font-medium mt-3 px-6 py-1 w-[75%]'
              onClick={handleStartGame}
              disabled={validationMessage !== ''}
            >
              Enter Game
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserModal;
