import React, { useState, useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import tambola from 'tambola';
import data1 from '../data.json';
import exitIcon from '../guidance_exit.png';
import ExitModal from './ExitModal';
import ExitAlertModal from './ExitAlertModal';


function Ticket({socket}) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ticketValue = searchParams.get('ticketValue');
  const [generatedNumbers, setGeneratedNumbers] = useState([]);
  const [exitModalVisible, setExitModalVisible] = useState(false);
  const [exitAlertModalVisible, setExitAlertModalVisible] = useState(false);

  const [beginLabel, setBeginLabel] = useState('Begin');
  const [joyLabel, setJoyLabel] = useState('The Joy');
  const gameID = searchParams.get('gameID');
  
  socket.emit('joinRoom', gameID);


  useEffect(() => {    
  
      socket.on('updateGeneratedNumber', (data) => {
         try{
        setGeneratedNumbers((prevGeneratedNumbers) => [...prevGeneratedNumbers, data.generatedNumber]);
        setBeginLabel(data.generatedNumber);
        setJoyLabel(data1[data.generatedNumber]);
        }
  catch(error){
    console.log(error);
  }

  socket.on('gameEnded', () => {
    setExitAlertModalVisible(true);
  });

      });  
    return () => {
      socket.disconnect();
    }
  }, [gameID, socket])
  
  

  
  function generateRandomCode(gameID) {
      const currentDate = new Date();
      const year = currentDate.getFullYear().toString().slice(-2); // Last two digits of the year
      const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Month as two digits
      const day = currentDate.getDate().toString().padStart(2, '0'); // Day as two digits
    
      // Generate a random 3-digit number between 100 and 999
      const randomDigits = Math.floor(Math.random() * 900) + 100;
    
      // Combine the game ID, date, and random digits to create an 8-digit ticket ID
      const ticketId = `${gameID}${year}${month}${day}${randomDigits}`;
    
      return ticketId;
    };

  const initialTicketData = useMemo(() => {
    const tickets = [];
    for (let i = 0; i < ticketValue; i++) {
      tickets.push({ ticket: tambola.generateTicket(), code: generateRandomCode(gameID) });
    }
    return tickets;
  }, [gameID,ticketValue]);

  const [clickedCellsArray, setClickedCellsArray] = useState(
    initialTicketData.map(() => ({}))
  );

const [lastClickedCellAddress, setLastClickedCellAddress] = useState(null);

const handleCellClick = (ticketIndex, rowIndex, colIndex) => {

  const cellValue = initialTicketData[ticketIndex].ticket[rowIndex][colIndex];
  var isClicked = false;
  // Check if the cell has a value (non-zero) before allowing the click
  if (cellValue !== 0) {

    setLastClickedCellAddress(`${rowIndex}-${colIndex}`);

    setClickedCellsArray((prevClickedCellsArray) => {
      const newClickedCellsArray = [...prevClickedCellsArray];
  
      // Check if the cell is already clicked
      if( `${rowIndex}-${colIndex}`=== lastClickedCellAddress){
         isClicked = newClickedCellsArray[ticketIndex][`${rowIndex}-${colIndex}`];
      }
  
      // Set the clicked state of the current cell to true
      newClickedCellsArray[ticketIndex][`${rowIndex}-${colIndex}`] = true;
  
      // If the cell was not already clicked, make all previously clicked cells unclickable
      if (!isClicked) {
        Object.keys(newClickedCellsArray[ticketIndex]).forEach((cellKey) => {
          if (cellKey !== lastClickedCellAddress) {
            newClickedCellsArray[ticketIndex][cellKey] = true;
          }
        });
      }    
      return newClickedCellsArray;
    });

  }
};

 const handlexit = () =>{
     setExitModalVisible(true);
  }
  
  const closeExitModal = () => {
    setExitModalVisible(false);
}
  
  const closeExitAlertModal = () => {
    setExitAlertModalVisible(false);
  }


  const renderTicket = (ticketData, ticketIndex) => { 
    const { ticket, code } = ticketData;

    return (
      <>
        <div key={ticketIndex}>
        <div className="flex flex-col font-mono h-auto bg-white w-80 my-5 mx-auto rounded-md border-gray-700 drop-shadow-lg text-left">
          <div className="grid grid-cols-9 gap-2 w-full p-4">
            {ticket.map((row, rowIndex) =>
              Array.from({ length: 9 }, (_, colIndex) => {
                const cellValue = row[colIndex] !== 0 ? row[colIndex] : '';
                const isClicked = clickedCellsArray[ticketIndex][`${rowIndex}-${colIndex}`];
                const cellStyle = isClicked ? 'bg-[#D3D196]' : 'bg-[#F8F7D2]';

                return (
                  <div
                    key={colIndex}
                    className={`h-6 w-6 flex items-center justify-center ${cellStyle}`}
                    onClick={() => handleCellClick(ticketIndex, rowIndex, colIndex)}
                  >
                    {cellValue}
                  </div>
                );
              })
            )}
          </div>
          <div className=' text-[10px] my-2 mx-4'>Ticket No: <span className='ml-3'>{code}</span></div>
        </div>
      </div>
      </>
    );
  };
  
  return (<>
    <div  className='conatiner mx-5 my-7 '>
        <div className='h-full ml-5'>
          <h6 className='text-small text-left tracking-widest font-semibold'>TAMBOLA</h6>
        </div>
        <button className='float-right mr-5' onClick={handlexit}>
          <img src={exitIcon} alt='exit' />
        </button>
        <div className=' text-left mx-5 my-4 text-[10px]'>Game ID:<span className='ml-4'>{gameID}</span>
      </div>
        <div className='h-full mt-10 mb-1'>
        <h6 className='my-1 text-5xl text-center tracking-widest font-bold text-[#7F8169] font-mono'>
          {beginLabel}
        </h6>
      </div>
      <div className="w-60 py-3 mt-2 m-auto bg-[#7F8169] border-gray-700 shadow-lg container h-20 items-center text-center  flex">
          <p id="output" className="text-white font-bold tracking-wider output text-center m-auto ">
            {joyLabel}
          </p>
        </div>
        <hr className=' border-[#7F8169] border my-5 w-72 m-auto'/>
        {initialTicketData.map((ticketData, index) => (
        <React.Fragment key={index}>
          {renderTicket(ticketData, index)}
        </React.Fragment>
        ))}
       {/* <div className='flex justify-between mx-3 my-10'>
       <div className='px-1  mt-6'>
          <Link to=''><button className='text-white bg-[#647D68]  py-2 px-3.5 border-black rounded-lg font-light text-xs tracking-normal'>Call Available</button></Link>
        </div>
        
       </div>*/}
                 <ExitModal isVisible={exitModalVisible} onClose={closeExitModal} socket={socket} />
                 <ExitAlertModal isVisible={exitAlertModalVisible} onClose={closeExitAlertModal} socket={socket} />
      </div>
      </>
    );

}
export default Ticket;