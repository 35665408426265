import React from 'react'
import { useNavigate } from 'react-router-dom';
import UserModal from './UserModal';
const apiUrl = 'https://server.tb.devlx.net/games/create-game'; 

function OTPModal({ isVisible, onClose, otp, allowedTickets }) {

  const navigate = useNavigate();

  const handleclick = () => {
    const gameIDM = generateGameId();
    const hostID = generateHostId();
    const gameData = { otp, allowedTickets, gameIDM, hostID };
    const gameId = `${gameIDM}`;
    
    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(gameData),
    })
      .then((response) => { 
        if (response.ok) {
          console.log('Game created successfully');
          navigate('/board', { state: { otp, gameId} });
        } else {
          console.error('Failed to create the game.');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
      navigate('/board', { state: { otp, gameId } });  
    }

  // Function to generate the game ID
  const generateGameId = () => {
    const currentDate = new Date();
    return `${currentDate.getFullYear()}${currentDate.getMonth()}${currentDate.getDate()}${currentDate.getHours()}${currentDate.getMinutes()}${currentDate.getSeconds()}`;
  };

  const generateHostId = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // Add 1 because months are zero-indexed
    const day = currentDate.getDate();
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const seconds = currentDate.getSeconds();
  
    // Combine the date and time components and ensure they have 2 digits
    const datePart = `${year}${month.toString().padStart(2, '0')}${day.toString().padStart(2, '0')}`;
    const timePart = `${hours.toString().padStart(2, '0')}${minutes.toString().padStart(2, '0')}${seconds.toString().padStart(2, '0')}`;
  
    // Combine date and time and take the last 5 characters to get a 5-digit ID
    const hostId = (datePart + timePart).slice(-5);
  
    return `${hostId}`;
  };
  

    if (!isVisible) {
        return null;
      }

  return (
    <div className='fixed inset-0 flex justify-center items-center bg-opacity-30 bg-black backdrop-blur-md z-50'>
      <div className='bg-white w-full h-auto overflow-hidden flex flex-col justify-between'>
        <div className='bg-[#E2DD20] text-center text-[#2C382E] h-[20%] py-4 text-3xl font-semibold tracking-wider font-sans'>
          OTP For Joining
        </div>
        <div className='bg-[#2C382E] p-4 h-full'>
        <div className='text-center text-lg font-medium my-4'>
  {otp.split('').map((digit, index) => (
    <span
      key={index}
      style={{
        fontFamily: 'Inter',
        fontSize: '30px',
        fontWeight: 500,
        lineHeight: '36px',
        letterSpacing: '0.49em',
        textAlign: 'center',
        WebkitTextStroke: '1.5px #E2DD30', // Webkit prefix for Safari
        textStroke: '1.5px #E2DD30', // Standard property
        padding: '3px', // Add padding to space out the digits
        display: 'inline-block', // Make each digit inline
        margin: '4px', // Add margin to space out the digits
      }}
    >
      {digit}
    </span>
  ))}
</div>
       <div className=' py-2.5'>
            <p className=' text-xl text-[#D3D196] tracking-wider'>Share this OTP with other players</p>
          </div>
          <div className='pb-2'>
          <button
            className='bg-[#E2DD20] text-[#2C382E] rounded-lg text-xl font-medium mt-3 px-6 py-1 w-[75%]'
            onClick={handleclick}
          >
            Start Game
          </button>
          </div>
          </div>
        </div>
      </div>
      
  )
}

export default OTPModal