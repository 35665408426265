import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import GroupImage from '../Group_30.png';
import CodeModal from '../components/CodeModal';
import JoinModal from '../components/JoinModal';
import HostModal from '../components/HostModal';
import UserModal from '../components/UserModal';
import JoinOTPModal from '../components/JoinOTPModal';

export default function Home(){
  const [isHostModalVisible, setIsHostModalVisible] = useState(false);
  const [isJoinOTPModalVisible, setIsJoinOTPModalVisible] = useState(false);

  const openHostModal = () => {
    setIsHostModalVisible(true);
  };

  const closeHostModal = () => {
    setIsHostModalVisible(false);
  }; 

  const openJoinOTPModal = () => {
    setIsJoinOTPModalVisible(true);
  };

  const closeJoinOTPModal = () => {
    setIsJoinOTPModalVisible(false);
  }; 


    return (
      <div className=' w-auto text-center'>
        <div className='header text-center border-l tracking-wide pt-10'>
          <h1 className='mt-7 tracking-widest text-4xl font-semibold text-[#2C282E] font-sans'>TAMBOLA</h1>
        </div>
        <div className='text-center'>
        <div className='my-9'>
            <center>
          <img src={GroupImage} alt='Group' className=' mt-2'/>
          </center>
        </div>

        <div className=' items-center mt-4 col-sm-12'>
          <div className='mx-24'>
          <button onClick={openHostModal} className='rounded-lg text-black bg-[#E2DD20]  py-2 px-3 mb-2 font-sans text-lg tracking-wide w-full'>    
            Create Game
          </button>
          <HostModal isVisible={isHostModalVisible} onClose={closeHostModal} />
          </div>
          <div className=' mx-24'>
          <button  onClick={openJoinOTPModal} className='text-[#E2DD40] bg-[#2C382E]  py-2 px-3 border-black rounded-lg font-light text-lg tracking-wide w-full font-sans'>
            Join Game</button>
            <JoinOTPModal isVisible={isJoinOTPModalVisible} onClose={closeJoinOTPModal} />
        </div>
        </div>
        </div>
        <div className='text-center mt-7 mx-10'>
          <p className='mx-3 text-sm'>
          Welcome to Tambola Web! Tap, match, and shout 'Tambola!' Dive into the excitement now!
          </p>
        </div>
      </div>
      
    );
  }