import React from 'react';
import { Oval } from 'react-loader-spinner';

const LoadingOverlay = () => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-md flex justify-center items-center z-50">
      <Oval
       height={50}
       width={50}
       color="#E2DD20"
       wrapperStyle={{}}
       visible={true}
       ariaLabel='oval-loading'
       secondaryColor="#4fa94d"
       strokeWidth={2}
       strokeWidthSecondary={2}
      />
    </div>
  );
};

export default LoadingOverlay;
