  import React from 'react'
  import {HashRouter,Routes , Route} from 'react-router-dom';
  import Home from './views/Home'
  import Board from './components/Board';
  import Invite from './components/Invite';
  import Ticket from './components/Ticket';
  import { io } from 'socket.io-client';

  function App() {
    const socket = io('https://server.tb.devlx.net/');
    return (
      <HashRouter>
        <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="/board" element={<Board socket={socket}/>} />
          <Route path='/invite' element={<Invite />} />
          <Route path='/ticket' element={<Ticket socket={socket}/>} />
        </Routes>
      </HashRouter>
    )
  }

  export default App