import React from 'react'
import { useNavigate } from 'react-router-dom'

function ExitAlertModal({isVisible , onClose, socket}) {

    const navigate = useNavigate();

    const handleclick = () => {
        navigate('/');
        
          // Close the Socket.IO connection when the component unmounts
          socket.disconnect();
    }

    if(!isVisible){
        return;
    }

  return (
    <div className='fixed inset-0 flex justify-center items-center bg-opacity-30 bg-black backdrop-blur-md z-50'>
    <div className='bg-white w-full h-auto overflow-hidden flex flex-col justify-between'>
      <div className='bg-[#E2DD20] text-center text-[#2C382E] h-[20%] py-4 text-3xl font-semibold tracking-wider font-sans'>
        Alert!
      </div>
      <div className='bg-[#2C382E] p-4 h-full text-center'>
      <div className=' py-2.5'>
            <p className=' text-xl text-[#D3D196] tracking-wider'>The game has been ended by the host please exit.</p>
          </div>
          <div className='py-2'>
          <button
            className='bg-[#C5C383] text-[#2C382E] rounded-lg text-xl font-medium px-6 py-1 w-[75%]'
            onClick={handleclick}
          >
            Exit Game
          </button>
          </div>
    </div>
  </div>
  </div>
  )
}

export default ExitAlertModal