import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import defaultimage from '../images.png';
import one from '../1.jpg'; 
import two from '../Realistic-Male-Profile-Picture.webp';
import three from '../Untitled.jpg';

function Invite() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const generatedCode = queryParams.get('code');

  const [playerData, setPlayerData] = useState([
    { id: 1, name: 'Shubham', img: one , selected : false},
    { id: 2, name: 'Madhav', img: two , selected : false},
    { id: 3, name: 'Amit', img: three , selected : false},
    { id: 4, name: 'Himanshu', img: one , selected : false},
    { id: 5, name: 'Shashav', img: one , selected : false},
    { id: 1, name: 'Shubham', img: one , selected : false},
    { id: 2, name: 'Madhav', img: two , selected : false},
    { id: 3, name: 'Amit', img: three , selected : false},
    { id: 4, name: 'Himanshu', img: one , selected : false},
    { id: 5, name: 'Shashav', img: one , selected : false},
    { id: 1, name: 'Shubham', img: one , selected : false},
    { id: 2, name: 'Madhav', img: two , selected : false},
    { id: 3, name: 'Amit', img: three , selected : false},
    { id: 4, name: 'Himanshu', img: one , selected : false},
    { id: 5, name: 'Shashav', img: one , selected : false},


  ]);

  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    // Simulating image loading delay for demonstration
    const timer = setTimeout(() => {
      setImagesLoaded(true);
    }, 1000); // Adjust this delay as needed

    return () => clearTimeout(timer);
  }, []);

  const handlePlayerClick = (playerId) => {
    setPlayerData(prevPlayerData => {
      return prevPlayerData.map(player => {
        if (player.id === playerId) {
          return { ...player, selected: !player.selected };
        }
        return player;
      });
    });
  };

  return (
    <div className='container h-screen flex flex-col'>
      <div className='header text-center border-l tracking-wide py-6'>
        <h1 className='tracking-widest text-3xl font-semibold text-[#2C282E] font-sans'>
          TAMBOLA
        </h1>
      </div>
      <div className='w-full bg-[#C5C383] py-1 flex'>
        <h7 className='text-center w-[20%] py-2 ml-8'>Game ID</h7>
        <p className='text-center w-[3%] py-2.5 font-light text-sm pl-5'>
          {generatedCode}
        </p>
      </div>
      <div className='bg-white flex-wrap flex flex-grow' style={{ overflowY: 'auto', maxHeight: '80vh' }}>
        {playerData.map((player) => (
          <div
            key={player.id}
            className={`w-[33%] text-center mt-10 px-5 py-auto ${
              player.selected ? '' : ''
            }`}
            onClick={() => handlePlayerClick(player.id)}
          >
            {imagesLoaded ? (
              <>
                <div
                  className={`relative rounded-full mx-auto cursor-pointer ${
                    player.selected ? '' : ''
                  }`}
                >
                  <img
                    className='rounded-full'
                    src={player.img ||  defaultimage}
                    alt={player.name}
                    style={{ borderRadius: '50%' }}
                  />
                  {player.selected && (
                    <div className='absolute bottom-0 right-0 p-1 bg-teal-800 text-white rounded-bl-full'>
                    ✓
                  </div>
                              
                  
                  )}
                </div>
                <p className='mt-3 text-sm'>{player.name}</p>
              </>
            ) : (
              <div className='w-[100px] h-[100px] rounded-full bg-gray-300 mx-auto'></div>
            )}
          </div>
        ))}
      </div>
      <a href='/board' className='mt-auto bg-[#2C382E] text-[#D3D196] text-center py-2.5 font-normal text-base hover:bg-[#D3D196] hover:text-[#2C382E]'>
    Start Game
      </a>

    </div>
  );
}

export default Invite;