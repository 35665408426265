// HostModal.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import OTPModal from './OTPModal'; // Import the OTPModal component

function HostModal({ isVisible, onClose }) {
  const [allowedTickets, setAllowedTickets] = useState(1);
  const [inputError, setInputError] = useState(null); // State for input error message
  const [otp, setOTP] = useState(''); // State for OTP
  const [otpModalVisible, setOTPModalVisible] = useState(false); // State for OTP modal visibility
  const navigate = useNavigate();
  
  const handleInputChange = (event) => {
    const enteredValue = event.target.value;
    if (enteredValue > 3) {
      setInputError('Enter a value below or equal to the maximum'); // Set input error message
    } else {
      setInputError(null); // Clear input error message if value is valid
      setAllowedTickets(enteredValue);
    }
  };

  const handleStartGame = () => {
    if (inputError) {
      // Don't proceed if there is an input error
      return;
    }

    const generatedOTP = Math.floor(1000 + Math.random() * 9000).toString();
    setOTP(generatedOTP);

    // Show the OTP modal by setting its visibility state to true
    setOTPModalVisible(true);

  };

  const closeOTPModal = () => {
    // Close the OTP modal by setting its visibility state to false
    setOTPModalVisible(false);
  };


  if (!isVisible) {
    return null;
  }

  return (
    <div className='fixed inset-0 flex justify-center items-center bg-opacity-30 bg-black backdrop-blur-md z-50'>
      <div className='bg-white w-full h-auto overflow-hidden flex flex-col justify-between'>
        <div className='bg-[#E2DD20] text-center text-[#2C382E] h-[20%] py-4 text-3xl font-semibold tracking-wider font-sans'>
          Tickets Per Player
        </div>
        <div className='bg-[#2C382E] p-4 h-full'>
          <div><small className=' text-[#818052] p-1 '>Max. 3 allowed</small></div>
          <div><input
            type='text'
            className='w-[50%] rounded-lg p-6 border-[#D3D196] h-10 border text-[#D3D196] text-center bg-[#2C382E] text-lg'
            value={allowedTickets}
            onChange={handleInputChange}
          />
          {inputError && (
            <p className='text-red-500 text-sm text-center my-3'>{inputError}</p>
          )}
          </div>
          <div className=' py-3.5'>
            <p className=' text-xl text-[#D3D196] tracking-wider'>Click the below button to get OTP</p>
          </div>
          <div className='pb-2'>
          <button
            className='bg-[#E2DD20] text-[#2C382E] rounded-lg text-xl font-medium mt-3 px-6 py-1 w-[75%]'
            onClick={handleStartGame}
          >
            Generate OTP
          </button>

          <OTPModal isVisible={otpModalVisible} onClose={closeOTPModal} otp={otp} allowedTickets={allowedTickets}/>
          
          </div>
        </div>
      </div>
    </div>
  );
}

export default HostModal;
