import React, { Component, useState, useEffect } from 'react';
import { Link  , useLocation } from 'react-router-dom';
import ScreenshotMonitorSharpIcon from '@mui/icons-material/ScreenshotMonitorSharp';
import Replay5SharpIcon from '@mui/icons-material/Replay5Sharp';
import html2canvas from 'html2canvas';
import data from '../data.json';

class TambolaBoard extends Component {
  constructor() {
    super();
    this.state = {
      board: [],
      matchingPosition: null,
      lastCalledNumbers: [],
      showPreviouslyCalled: false,
      currentNumber: 'Begin',
      showUserView: false,
      beginLabel: 'Begin', // Initialize Begin label
      joyLabel: 'The Joy',
      playerCount: 0, 
      expireFlag: false,

    };
    this.boardContainer = React.createRef();
    this.numbersToCall = Array.from({ length: 90 }, (_, i) => i + 1);
    this.shuffleNumbers();
  }

  componentDidMount() {
    this.generateBoard();
    const { location, socket } = this.props;
    const { otp, gameId } = location.state || {};

    // Initialize a Socket.IO connection

    // Listen for 'updatePlayerCount' event from the server and update the player count
    socket.on('updatePlayerCount', ({ playerCount }) => {
      this.setState({ playerCount });
    });

    // Emit 'joinRoom' event to join the game room
    socket.emit('joinRoom', gameId);

  socket.on('boardUpdate', ({ beginLabel, joyLabel, gameId }) => {

  // Handle real-time board updates
  // You can update the labels, perform other actions, etc.
  this.setState({ beginLabel, joyLabel });
  
  // Emit the 'updateLabels' event to the server, specifying the room (gameID)
   socket.emit('updateLabels', { beginLabel, joyLabel, gameId });
  });

  }

  callApi = () => {
    const { location } = this.props;
  const { otp } = location.state || {};

    fetch('https://server.tb.devlx.net/games/expire-otp', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ otp }),
    })
      .catch((error) => {
        // Handle errors, e.g., show an error message to the user
        console.error('API request failed:', error);
      });

      this.expireFlag = true;
  };


  shuffleNumbers() {
    for (let i = this.numbersToCall.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [this.numbersToCall[i], this.numbersToCall[j]] = [this.numbersToCall[j], this.numbersToCall[i]];
    }
  }

  generateBoard() {
    const board = [];
    for (let row = 0; row < 9; row++) {
      const rowNumbers = [];
      for (let col = 0; col < 10; col++) {
        rowNumbers.push(row * 10 + col + 1);
      }
      board.push(rowNumbers);
    }
    this.setState({ board });
  }

  
  captureScreenshot = async () => {
    if (this.boardContainer.current) {
      const canvas = await html2canvas(this.boardContainer.current);
      const imgData = canvas.toDataURL('image/png');
      const newWindow = window.open();
      newWindow.document.write(`<img src="${imgData}" alt="Screenshot" />`);
    }
  };

  generateRandom(min, max) {

    let difference = max - min;

    let rand = Math.random();

    rand = Math.floor( rand * difference);

    rand = rand + min;

    return rand;
}

 findNumberInBoard(number) {
    for (let row = 0; row < this.state.board.length; row++) {
      for (let col = 0; col < this.state.board[row].length; col++) {
        if (this.state.board[row][col] === number) {
          return { row, col }; // Return the position of the matching number
        }
      }
    }
    return null; // Number not found on the board
  }

  handleButtonClick = () => {
    const { location, socket } = this.props;
    const { otp, gameId } = location.state || {};

    if (this.numbersToCall.length === 0) {
      return; // All numbers have been called
    }

    if (!this.expireFlag) {
      this.callApi();
    }

    const generatedNumber = this.numbersToCall.pop();
    const matchingPosition = this.findNumberInBoard(generatedNumber);
    const lastCalledNumbers = [...this.state.lastCalledNumbers];

    socket.emit('generatedNumber', { generatedNumber: generatedNumber, gameId: gameId });

    lastCalledNumbers.unshift({ number: generatedNumber, position: matchingPosition });

    this.setState({
      generatedNumber,
      matchingPosition,
      lastCalledNumbers,
      showPreviouslyCalled: false,
      currentNumber: generatedNumber,
    });
  };

  handleEndGame = () => {
    const {socket} = this.props;
    const { gameId } = this.props.location.state || {};

    // Emit an event to inform the server that the game is ending
   socket.emit('endGame', { gameId: gameId });
    
  };


  toggleShowPreviouslyCalled = () => {
    this.setState(prevState => ({
      showPreviouslyCalled: !prevState.showPreviouslyCalled,
    }));
  };

  findNumberInLastCalled(number){
    return this.state.lastCalledNumbers.some(item => item.number === number);
  }

  resizeTextToFit() {
    const output = document.querySelector('.output');
    const outputContainer = document.querySelector('.container');

    let fontSize = window.getComputedStyle(output).fontSize;
    output.style.fontSize = parseFloat(fontSize) - 1 + 'px';

    if (output.clientHeight >= outputContainer.clientHeight) {
      this.resizeTextToFit();
    }
  }

  processInput = (event) => {
    const inputValue = event.target.value;
    const outputElement = document.querySelector('.output');
    const outputContainer = document.querySelector('.container');

    outputElement.innerHTML = inputValue;
    outputElement.style.fontSize = '100px'; // Default font size

    this.resizeTextToFit();
  };

  render() {
    const { showUserView, currentNumber, beginLabel, joyLabel } = this.state;
    const min = 0;
    const max = 90;
    const generatedNumber = this.generateRandom(min, max);
    const currentNumberText =
      this.state.currentNumber === 'Begin' ? joyLabel : data[this.state.currentNumber];    
      const { location } = this.props;
      const { otp, gameId } = location.state || {};


    return (
      <div ref={this.boardContainer} className=' mx-5 my-2 min-h-screen'>
        <div>
          <p className='text-sm text-left tracking-wider font-semibold'>TAMBOLA<span className='float-right mr-5 text-xs'>OTP:{otp}</span></p>
        </div>
        <div className='my-1 text-5xl text-center tracking-widest font-bold text-[#7F8169] font-mono'>
          {this.state.currentNumber}
        </div>
        <div className="w-60 py-3 mt-2 m-auto bg-[#7F8169] border-gray-700 shadow-lg container h-20 items-center text-center  flex">
          <p id="output" className="text-white font-bold tracking-wider output text-center m-auto ">
            {currentNumberText}
          </p>
        </div>
        <hr className=' border-[#7F8169] border my-5 w-92 m-auto'/>
        <div className="flex flex-col items-center font-mono">
      {this.state.board.map((row, rowIndex) => (
        <div key={rowIndex} className="flex">
          {row.map((number, colIndex) => {
          const isCurrentNumber =
            this.state.matchingPosition &&
            this.state.matchingPosition.row === rowIndex &&
            this.state.matchingPosition.col === colIndex;

         const isPreviouslyCalled = this.findNumberInLastCalled(number);

          let cellClassName = 'w-8 h-8 border-black flex justify-center items-center m-0.5';

          if (isCurrentNumber) {
            cellClassName += ' bg-[#7F8169] rounded-full font-semibold text-white';
          } else if (isPreviouslyCalled) {
           cellClassName += ' bg-[#D3D196] rounded-full font-semibold'; // Light color for previously called
          } else {
           cellClassName += ' bg-white'; // Default color for unmarked cells
         }

          return (
           <div key={number} className={cellClassName}>
             {number}
           </div>
         );
        })}

        </div>
      ))}
    </div>
    <hr className=' border-black border my-5 w-92 m-auto'/>
      <div className='flex justify-between mx-3'>
          <div className='justify-between'>
            <ScreenshotMonitorSharpIcon
              className='ml-0.5 cursor-pointer'
              sx={{ fontSize: 28 }}
              onClick={this.captureScreenshot}  
            />
            <Replay5SharpIcon
              className="ml-4"
              sx={{ fontSize: 28 }}
              onClick={() => this.setState(prevState => ({ showPreviouslyCalled: !prevState.showPreviouslyCalled }))}
            />
          </div>
          <div className='px-1'>
            <button  onClick={this.handleButtonClick} className='text-[#2C382E] bg-[#E2DD30] py-2 px-5 border-black rounded-lg font-light text-xs tracking-tight'>
              Call first
            </button>
          </div>
        </div>
        <hr className=' border-black border my-5 w-92 m-auto'/>
       <div className='flex justify-between mx-3'>
        <div className='justify-between'>
         <ul className='list-none'>
          <li className='flex items-center mb-2 text-sm tracking-tight'>
      <span className='h-5 w-5 mr-2 rounded-full bg-[#D3D196] '></span>
         Previously Called
       </li>
       <li className='flex items-center text-sm mt-4 tracking-tight'>
       <span className='h-5 w-5 mr-2 rounded-full bg-[#7F8169]'></span>
      Current Call
      </li>
      </ul>
    </div>
        <div className='px-1  mt-6'>
          <Link to='/'><button className='text-white bg-[#B42C2E]  py-2 px-3.5 border-black rounded-lg font-light text-xs tracking-normal' onClick={this.handleEndGame}>End Game</button></Link>
        </div>
       </div>
       <div className="mt-9 ml-3 text-xs text-left">
         Game ID: <span className="ml-3">{gameId}</span>
        <br/>
        Players: <span className="ml-3">{this.state.playerCount}</span>
       </div>
      </div>
    );
  }
}

function Board({socket}) {
  const location = useLocation();
  const { gameId } = location.state || {};

  return (
    <div className='text-center mt-12'>
      <TambolaBoard gameId={gameId} location={location} socket={socket}/>
    </div>
  );
}

export default Board;